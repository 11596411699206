<template>
  <div class="page-template">
    <h2 class="p-title">模板详情</h2>
    <yos-tab-nav :list="tabNav" />
    <bread-crumbs :list="breadList" />
    <div class="p-card fix">
      <div class="example" v-if="detail.example">
        <div class="box">
          <div class="wrapper">
            <p v-for="(item, idx) in example" :key="idx">{{ item }}</p>
          </div>
          <div class="link">详情</div>
        </div>
        <div class="notice">内容示例</div>
      </div>
      <div class="detail">
        <div class="item">
          <label>模版ID</label>
          <div class="content">{{ detail.templateId }}</div>
        </div>
        <div class="item">
          <label>标题</label>
          <div class="content">{{ detail.title }}</div>
        </div>
        <div class="item">
          <label>行业</label>
          <div class="content">{{ detail.deputyIndustry }}</div>
        </div>
        <div class="item">
          <label>详细内容</label>
          <div class="content">
            <p v-for="(item, idx) in content" :key="idx">{{ item }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YosTabNav from '../../components/other/tab-nav'
import BreadCrumbs from '../../components/common/bread-crumbs'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useTabNav } from '../../hook'
import { getPageNavs } from '../../util'

export default {
  components: {
    YosTabNav,
    BreadCrumbs
  },
  setup() {
    const route = useRoute()
    const nav = getPageNavs('fun')
    nav[1].active = true
    const breadList = [{
      name: '我的模板',
      to: `/fun/templates?appid=${route.params.appid}`
    }, {
      name: '模板详情'
    }]
    const detail = JSON.parse(sessionStorage.getItem(route.params.id))
    const example = computed(() => (detail.example || '').split(/\n/))
    const content = computed(() => (detail.content || '').split(/\n/))

    return {
      tabNav: useTabNav(nav),
      breadList,
      detail,
      example,
      content
    }
  }
}
</script>

<style lang="postcss">
.page-template {
  .example {
    float: left;
    width: 322px;
    margin-right: 70px;

    .box {
      width: 320px;
      border: 1px solid #e4e8eb;

      .wrapper {
        padding: 18px 20px;

        p {
          min-height: 21px;
        }
      }

      .link {
        padding: 0 20px;
        border-top: 1px solid #e4e8eb;
        line-height: 44px;
        position: relative;
        color: #9a9a9a;

        &:after {
          content: "";
          display: block;
          width: 5px;
          height: 5px;
          border-top: 1px solid #9a9a9a;
          border-right: 1px solid #9a9a9a;
          transform: rotate(45deg);
          position: absolute;
          right: 20px;
          top: 20px;
        }
      }
    }

    .notice {
      text-align: center;
      color: #9a9a9a;
      padding-top: 30px;
    }
  }

  .detail {
    overflow: hidden;

    .item {
      padding-bottom: 30px;

      label {
        width: 6em;
        margin-right: 20px;
        float: left;
      }

      .content {
        overflow: hidden;

        p {
          min-height: 21px;
        }
      }
    }
  }
}
</style>
